import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseURL, key } from "../../util/config"; // Assuming you have these already configured

const BrandComponent = () => {
  const [brands, setBrands] = useState([]);
  const [newBrand, setNewBrand] = useState("");
  const [editBrand, setEditBrand] = useState({ id: "", name: "" });

  const token = localStorage.getItem("token");

  // Axios instance
  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
      key: key,
      Authorization: `${token}`,
    },
  });

  // Fetch Brands
  const fetchBrands = async () => {
    try {
      const response = await axiosInstance.get(`/brand/getAllBrands`);
      setBrands(response.data.brands); // Assuming response has a 'brands' key
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  // Create Brand
  const createBrand = async () => {
    if (!newBrand) return;
    try {
      const response = await axiosInstance.post(`/brand/create`, { name: newBrand });
      setBrands([response.data, ...brands]); // Add new brand to the list
      setNewBrand(""); // Clear input
    } catch (error) {
      console.error("Error creating brand:", error);
    }
  };

  // Update Brand
  const updateBrand = async () => {
    if (!editBrand.id || !editBrand.name) return;
    try {
      const response = await axiosInstance.put(`/brand/delete/${editBrand.id}`, {
        name: editBrand.name,
      });
      setBrands(
        brands.map((brand) =>
          brand._id === editBrand.id ? response.data : brand
        )
      );
      setEditBrand({ id: "", name: "" }); // Reset edit form
    } catch (error) {
      console.error("Error updating brand:", error);
    }
  };

  // Delete Brand
  const deleteBrand = async (id) => {
    try {
      await axiosInstance.delete(`/brand/update/${id}`);
      setBrands(brands.filter((brand) => brand._id !== id)); // Remove brand from list
    } catch (error) {
      console.error("Error deleting brand:", error);
    }
  };

  // Fetch brands on component mount
  useEffect(() => {
    fetchBrands();
  }, []);

  return (
    <div>
      <h1>Brand Management</h1>

      {/* Add New Brand */}
      <div>
        <input
          type="text"
          value={newBrand}
          onChange={(e) => setNewBrand(e.target.value)}
          placeholder="Enter new brand"
        />
        <button onClick={createBrand}>Add Brand</button>
      </div>

      {/* Edit Brand */}
      {editBrand.id && (
        <div>
          <input
            type="text"
            value={editBrand.name}
            onChange={(e) => setEditBrand({ ...editBrand, name: e.target.value })}
            placeholder="Update brand name"
          />
          <button onClick={updateBrand}>Update Brand</button>
          <button onClick={() => setEditBrand({ id: "", name: "" })}>Cancel</button>
        </div>
      )}

      {/* Brand List */}
      <ul>
        {brands.map((brand) => (
          <li key={brand._id}>
            {brand.name}{" "}
            <button onClick={() => setEditBrand({ id: brand._id, name: brand.name })}>
              Edit
            </button>
            <button onClick={() => deleteBrand(brand._id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BrandComponent;
