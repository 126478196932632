import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import { createCategory, updateCategory } from "../../store/category/category.action";

const CategoryDialog = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [mongoId, setMongoId] = useState(0);
  const [name, setName] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [isSensitiveContent, setIsSensitiveContent] = useState(false);
  const [isGenderChecked, setIsGenderChecked] = useState(false);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState("");
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [newProductCommission, setNewProductCommission] = useState("");
  const [secondHandProductCommission, setSecondHandProductCommission] = useState("");
  const [antiqueProductCommission, setAntiqueProductCommission] = useState("");
  const [collectionProductCommission, setCollectionProductCommission] = useState("");
  const [error, setError] = useState({
    name: "",
    image: "",
    ageGroup: "",
    genders: "",
    newProductCommission: "",
    secondHandProductCommission: "",
    antiqueProductCommission: "",
    collectionProductCommission: "",
  });


  const dispatch = useDispatch();

  useEffect(() => {
    setMongoId(dialogueData?._id);
    setName(dialogueData?.name);
    setImagePath(dialogueData?.image);
    setIsSensitiveContent(dialogueData?.isSensitiveContent || false);
  }, [dialogueData]);

  const handleImage = (e) => {
    setError((prevErrors) => ({
      ...prevErrors,
      image: "",
    }));
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const handleGenderChange = (gender) => {
    if (selectedGenders.includes(gender)) {
      setSelectedGenders(selectedGenders.filter((g) => g !== gender));
    } else {
      setSelectedGenders([...selectedGenders, gender]);
    }
  };

  const handleSubmit = () => {
    let formHasError = false;
    let errorObj = { ...error };

    if (!name || !imagePath) {
      if (!name) errorObj.name = "Name is Required!";
      if (!imagePath) errorObj.image = "Image is required!";
      formHasError = true;
    }
    const commissionFields = [
      { value: newProductCommission, name: "newProductCommission" },
      { value: secondHandProductCommission, name: "secondHandProductCommission" },
      { value: antiqueProductCommission, name: "antiqueProductCommission" },
      { value: collectionProductCommission, name: "collectionProductCommission" },
    ];

    commissionFields.forEach(({ value, name }) => {
      if (value === "" || isNaN(value) || value < 0 || value > 100) {
        errorObj[name] = `${name.replace(/([A-Z])/g, ' $1')} must be a number between 0 and 100.`;
        formHasError = true;
      }
    });

    if (isGenderChecked && !selectedAgeGroup) {
      errorObj.ageGroup = "You must select either Adult or Child.";
      formHasError = true;
    }

    if (isGenderChecked && selectedAgeGroup && selectedGenders.length === 0) {
      errorObj.genders = "You must select at least one gender option.";
      formHasError = true;
    }

    if (formHasError) {
      return setError({ ...errorObj });
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("image", image);
      formData.append("isSensitiveContent", isSensitiveContent);
      formData.append("isGenderChecked", isGenderChecked);
      formData.append("selectedAgeGroup", selectedAgeGroup);
      formData.append("selectedGenders", JSON.stringify(selectedGenders));
      formData.append("newProductCommission", newProductCommission);
      formData.append("secondHandProductCommission", secondHandProductCommission);
      formData.append("antiqueProductCommission", antiqueProductCommission);
      formData.append("collectionProductCommission", collectionProductCommission);

      if (mongoId) {
        dispatch(updateCategory(formData, mongoId));
      } else {
        dispatch(createCategory(formData));
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };

  return (
    <div className="mainDialogue fade-in">
      <div className="Dialogue">
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">Category</div>
          <div
            className="closeBtn "
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="dialogueMain">
          <div className="row">
            <div className="col-12">
              <Input
                label={`Name`}
                id={`name`}
                newClass={`text-capitalize`}
                type={`text`}
                value={name}
                errorMessage={error.name && error.name}
                onChange={(e) => {
                  setName(e.target.value);
                  setError((prevErrors) => ({ ...prevErrors, name: "" }));
                }}
              />
            </div>
            <div className="col-12">
              <Input
                label={`Image`}
                id={`image`}
                type={`file`}
                accept={`image/*`}
                errorMessage={error.image && error.image}
                onChange={handleImage}
              />
              {imagePath && (
                <div className="image-start">
                  <img
                    src={imagePath}
                    alt="banner"
                    draggable="false"
                    width={100}
                    className="m-0"
                  />
                </div>
              )}
            </div>
            <div className="col-12">
              <label>
                <input
                  type="checkbox"
                  checked={isSensitiveContent}
                  onChange={(e) => setIsSensitiveContent(e.target.checked)}
                />
                Sensitive Content
              </label>
            </div>

            <div className="col-12">
              <Input
                label={`New Product Commission (%)`}
                id={`newProductCommission`}
                type={`number`}
                value={newProductCommission}
                errorMessage={error.newProductCommission && error.newProductCommission}
                onChange={(e) => {
                  setNewProductCommission(e.target.value);
                  setError((prevErrors) => ({ ...prevErrors, newProductCommission: "" }));
                }}
              />
            </div>
            <div className="col-12">
              <Input
                label={`Second-Hand Product Commission (%)`}
                id={`secondHandProductCommission`}
                type={`number`}
                value={secondHandProductCommission}
                errorMessage={error.secondHandProductCommission && error.secondHandProductCommission}
                onChange={(e) => {
                  setSecondHandProductCommission(e.target.value);
                  setError((prevErrors) => ({ ...prevErrors, secondHandProductCommission: "" }));
                }}
              />
            </div>

            <div className="col-12">
              <Input
                label={`Antique Product Commission (%)`}
                id={`antiqueProductCommission`}
                type={`number`}
                value={antiqueProductCommission}
                errorMessage={error.antiqueProductCommission && error.antiqueProductCommission}
                onChange={(e) => {
                  setAntiqueProductCommission(e.target.value);
                  setError((prevErrors) => ({ ...prevErrors, antiqueProductCommission: "" }));
                }}
              />
            </div>

            <div className="col-12">
              <Input
                label={`Collection Product Commission (%)`}
                id={`collectionProductCommission`}
                type={`number`}
                value={collectionProductCommission}
                errorMessage={error.collectionProductCommission && error.collectionProductCommission}
                onChange={(e) => {
                  setCollectionProductCommission(e.target.value);
                  setError((prevErrors) => ({ ...prevErrors, collectionProductCommission: "" }));
                }}
              />
            </div>

            {/* Gender selection checkbox */}
            <div className="col-12 mt-3">
              <label>
                <input
                  type="checkbox"
                  checked={isGenderChecked}
                  onChange={(e) => setIsGenderChecked(e.target.checked)}
                />
                Specify Gender
              </label>

              {isGenderChecked && (
                <>
                  {/* Age Group Selection */}
                  <div className="mt-2">
                    <label>
                      <input
                        type="radio"
                        value="Adult"
                        checked={selectedAgeGroup === "Adult"}
                        onChange={(e) => {
                          setSelectedAgeGroup(e.target.value);
                          setSelectedGenders([]); // Yaş grubu değiştiğinde alt cinsiyet seçeneklerini temizle
                          setError((prevErrors) => ({ ...prevErrors, ageGroup: "", genders: "" }));
                        }}
                      />
                      Adult
                    </label>
                    <label className="ms-3">
                      <input
                        type="radio"
                        value="Child"
                        checked={selectedAgeGroup === "Child"}
                        onChange={(e) => {
                          setSelectedAgeGroup(e.target.value);
                          setSelectedGenders([]);
                          setError((prevErrors) => ({ ...prevErrors, ageGroup: "", genders: "" }));
                        }}
                      />
                      Child
                    </label>
                    {error.ageGroup && <p className="text-danger">{error.ageGroup}</p>}
                  </div>

                  {/* Gender Options Based on Age Group */}
                  {selectedAgeGroup && (
                    <div className="mt-2">
                      {selectedAgeGroup === "Adult" && (
                        <>
                          <label>
                            <input
                              type="checkbox"
                              value="Female"
                              checked={selectedGenders.includes("Female")}
                              onChange={() => handleGenderChange("Female")}
                            />
                            Female
                          </label>
                          <label className="ms-3">
                            <input
                              type="checkbox"
                              value="Male"
                              checked={selectedGenders.includes("Male")}
                              onChange={() => handleGenderChange("Male")}
                            />
                            Male
                          </label>
                          <label className="ms-3">
                            <input
                              type="checkbox"
                              value="Unisex"
                              checked={selectedGenders.includes("Unisex")}
                              onChange={() => handleGenderChange("Unisex")}
                            />
                            Unisex
                          </label>
                        </>
                      )}

                      {selectedAgeGroup === "Child" && (
                        <>
                          <label>
                            <input
                              type="checkbox"
                              value="Girl"
                              checked={selectedGenders.includes("Girl")}
                              onChange={() => handleGenderChange("Girl")}
                            />
                            Girl
                          </label>
                          <label className="ms-3">
                            <input
                              type="checkbox"
                              value="Boy"
                              checked={selectedGenders.includes("Boy")}
                              onChange={() => handleGenderChange("Boy")}
                            />
                            Boy
                          </label>
                          <label className="ms-3">
                            <input
                              type="checkbox"
                              value="Unisex"
                              checked={selectedGenders.includes("Unisex")}
                              onChange={() => handleGenderChange("Unisex")}
                            />
                            Unisex
                          </label>
                        </>
                      )}

                      {error.genders && <p className="text-danger">{error.genders}</p>}
                    </div>
                  )}
                </>
              )}
            </div>

          </div>
        </div>
        <div className="dialogueFooter">
          <div className="dialogueBtn">
            {!mongoId ? (
              <Button
                btnName={`Submit`}
                btnColor={`btnBlackPrime text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
            ) : (
              <Button
                btnName={`Update`}
                btnColor={`btnBlackPrime text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
            )}
            <Button
              btnName={`Close`}
              btnColor={`bg-danger text-white`}
              style={{ borderRadius: "5px", width: "80px" }}
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { createCategory, updateCategory })(CategoryDialog);
